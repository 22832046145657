import React from "react";
import Footer from "../../../components/Footer/Footer";
import CategoryLeftNav from "../../../components/TwoColumnLeftNav/CategoryLeftNav";
import {graphql} from "gatsby";
import Pagination from "../../../components/Pagination/Pagination";
import useTranslationsCat from "../../../components/useTranslationsCat";

const OptionalProbes = ({ data: { allMdx } }) => {
    const { optionalProbes } = useTranslationsCat()
    return (
        <>
            <article className="category-page two-column-left-nav">
                <CategoryLeftNav />
                <section className="main-content">
                    <section className="main-content">
                        <div className="content-container">
                            <h2 className="large bold">{ optionalProbes }</h2>
                            <Pagination posts={allMdx.edges} />
                        </div>
                    </section>
                </section>
            </article>
            <Footer />
        </>
    );
};

export default OptionalProbes;

export const query = graphql`
	query OptionalProbes($locale: String!) {
		allMdx(
			filter: {
				fileAbsolutePath: { regex: "/(product)/.*\\\\.mdx$/" }
				frontmatter: {  ignoreSearch: { ne: "true"}, categories: { in: [
				"optional_probes",
				"for_sk_l751",
				"for_sk_l754",
				"for_sk_l700r_t",
				"for_sk_l700r_th",
				"for_sk_l200TII_Series",
				"for_sk_l200th2a_series",
				"for_sk_l210t",
				"for_sk_l400t_sk_1110_sk_1120_sk_7000PRTII",
				"for_sk_1260",
				"for_sk_1250MCIII_sk_1250MCIIIa",
				"for_sk_270wp_series",
				"for_sk_250wp2_series",
				"for_sk_100wp",
				"for_sk_810pt",
				"for_sk_rhc_series",
				"for_sk_m460_t_sk_m350_t",
				"for_sk_m350r_t",
				"for_sk_m350r_trh",
				"for_sk_610ph_2",
				"for_sk_660ph_series",
				"for_sk_620ph2_sk_650ph",
				"for_sk_620ph",
				] } }
				fields: { locale: { eq: $locale } }
			}
			sort: { fields: [frontmatter___sku], order: ASC }
		) {
			edges {
				node {
					frontmatter {
						sku
						name
						model
						price
						productImages
					}
					fields {
						locale
					}
					parent {
						... on File {
							relativeDirectory
						}
					}
				}
			}
		}
	}
`;
